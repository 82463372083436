import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import { useLocation } from 'react-router-dom';
import { postRequest } from "../utils/api";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordConditions, setPasswordConditions] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });
  const [tokenValue, setTokenValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    setTokenValue(token);
  }, [location.search]);

  useEffect(() => {
    const conditions = {
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[@$&!?]/.test(password),
    };
    setPasswordConditions(conditions);
  }, [password]);

  const isPasswordValid = Object.values(passwordConditions).every(Boolean) && password === confirmPassword;

  const handleResetPassword = async () => {
    if (isPasswordValid) {
      const body = {
        newPassword: password,
        passwordResetToken: tokenValue,
      };
      try {
        const resetUrl = `${process.env.REACT_APP_HOSTNAME}/api/auth/resetPassword`;
        const response = await postRequest(resetUrl, body);
        if (response.result === 1) {
          setSuccessMessage('Password reset successful!');
          setErrorMessage('');
          // Optionally, navigate to another page after a delay
          setTimeout(() => navigate('/'), 3000);
        } else {
          setErrorMessage('Password reset failed. Please try again.');
          setSuccessMessage('');
        }
      } catch (error) {
        setErrorMessage('Password reset failed. Please try again.');
        setSuccessMessage('');
        console.error('Password reset failed:', error);
      }
    }
  };

  return (
    <Container>
      <Header>
        <LogoContainer>
          <Logo onClick={() => { navigate('/'); }} src={logo} alt="Pawers Logo" />
        </LogoContainer>
      </Header>
      <Form>
        <Title>Reset Password</Title>
        <Input
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <ConditionList>
          <Condition isValid={passwordConditions.length}>At least 8 characters long</Condition>
          <Condition isValid={passwordConditions.lowercase}>At least one lowercase letter (a-z)</Condition>
          <Condition isValid={passwordConditions.uppercase}>At least one uppercase letter (A-Z)</Condition>
          <Condition isValid={passwordConditions.number}>At least one number (0-9)</Condition>
          <Condition isValid={passwordConditions.specialChar}>At least one special character (@$&!?...)</Condition>
        </ConditionList>
        <ResetButton
          disabled={!isPasswordValid}
          onClick={handleResetPassword}
        >
          Reset Password
        </ResetButton>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      </Form>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f5f7fc; 
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  width: 200px;
  height: auto;

  @media (max-width: 600px) {
    width: 150px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const ConditionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  width: 100%;
`;

const Condition = styled.li`
  color: ${(props) => (props.isValid ? 'green' : 'red')};
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

const ResetButton = styled.button`
  background-color: ${(props) => (props.disabled ? '#ccc' : '#007bff')};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s;
  width: 100%;
  max-width: 200px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: green;
  margin-top: 10px;
`;

export default ResetPassword;
