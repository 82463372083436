import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AdoptPet from './pages/AdoptPet';
import PetSitters from './pages/PetSitters';
import PetTrainers from './pages/PetTrainers';
import PetWalkers from './pages/PetWalkers';
import PetGroomers from './pages/PetGroomers';
import PetVets from './pages/PetVets';
import PetProfile from './pages/PetProfile';
import PetAdoptionProfile from './pages/PetAdoptionProfile';
import PetSitterDetail from './pages/PetSitterDetail';
import PetTrainerDetail from './pages/PetTrainerDetail';
import PetGroomerDetail from './pages/PetGroomerDetail';
import PetWalkerDetail from './pages/PetWalkerDetail';
import PetVetDetail from './pages/PetVetDetail';
import Support from './pages/Support';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import ResetPassword from './pages/ResetPassword';
import VerifyEmail from './pages/VerifyEmail';
import AcceptPetInvite from './pages/AcceptPetInvite';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/adopt" element={<AdoptPet />} />
        <Route path="/sitters" element={<PetSitters />} />
        <Route path="/trainers" element={<PetTrainers />} />
        <Route path="/walkers" element={<PetWalkers />} />
        <Route path="/groomers" element={<PetGroomers />} />
        <Route path="/vets" element={<PetVets />} />
        <Route path="/Pets/Details" element={<PetProfile />} />
        <Route path="/PetsAdoption/Details" element={<PetAdoptionProfile />} />
        <Route path="/pet-sitter/:id" element={<PetSitterDetail />} />
        <Route path="/pet-trainer/:id" element={<PetTrainerDetail />} />
        <Route path="/pet-walker/:id" element={<PetWalkerDetail />} />
        <Route path="/pet-groomer/:id" element={<PetGroomerDetail />} />
        <Route path="/pet-vet/:id" element={<PetVetDetail />} />
        <Route path="/support" element={<Support />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/VerifyEmail" element={<VerifyEmail />} />
        <Route path="/AcceptPetInvite" element={<AcceptPetInvite />} />
      </Routes>
    </Router>
  );
}

export default App;
