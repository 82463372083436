import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/logo.png";
import petsImage from "../assets/pets.png";
import appStoreLogo from "../assets/appstore.png";
import playStoreLogo from "../assets/playstore.png";
import loading_cat from "../assets/loading_cat.gif";
import AdoptPetImg from "../assets/AdoptPet.png";
import FindSitterImg from "../assets/FindSitter.png";
import FindTrainerImg from "../assets/FindTrainer.png";
import FindVetImg from "../assets/FindVet.svg";
import FindGroomerImg from "../assets/FindGroomer.png"
import FindWalkerImg from "../assets/FindWalker.svg"
import pawtagImage from "../assets/pawtag.png"; // Import the image

const Home = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/Pets/Details?publicId=${searchTerm}`);
  };

  if (loading) {
    return (
      <LoadingContainer>
        <img src={loading_cat} alt="Loading..." />
      </LoadingContainer>
    );
  }

  return (
    <HomeContainer>
      <LogoContainer>
        <Logo src={logo} alt="Pawers Logo" />
      </LogoContainer>
      <ImageContainer>
        <PetsImage src={petsImage} alt="Pets Image" />
      </ImageContainer>
      <TextContainer>
        <SmallText>ACCESS IT ANYTIME, ANYWHERE</SmallText>
        <LargeText>
          YOUR PET'S PROFILE AND MEDICAL INFORMATION CONVENIENTLY AT HAND IN THE
          CLOUD
        </LargeText>
      </TextContainer>
      <DownloadContainer>
        <DownloadLink href="https://www.apple.com/app-store/" target="_blank">
          <AppStoreLogo src={appStoreLogo} alt="Download on the App Store" />
        </DownloadLink>
        <DownloadLink href="https://play.google.com/store/apps/details?id=com.mypawers.pawers" target="_blank">
          <PlayStoreLogo src={playStoreLogo} alt="Get it on Google Play" />
        </DownloadLink>
      </DownloadContainer>
      <SearchContainer>
        <SearchForm onSubmit={handleSearch}>
          <SearchInput
            type="text"
            placeholder="Enter Pet Public ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <SearchButton type="submit">Search</SearchButton>
        </SearchForm>
      </SearchContainer>
      <ButtonNav>
        <NavButton onClick={() => navigate("/adopt")}>
          <NavButtonImage src={AdoptPetImg} alt="Adopt Pet" />
          <NavButtonText>Adopt Pet</NavButtonText>
        </NavButton>
        <NavButton onClick={() => navigate("/sitters")}>
          <NavButtonImage src={FindSitterImg} alt="Find Sitter" />
          <NavButtonText>Find Sitter</NavButtonText>
        </NavButton>
        <NavButton onClick={() => navigate("/walkers")}>
          <NavButtonImage src={FindWalkerImg} alt="Find Walker" />
          <NavButtonText>Find Walker</NavButtonText>
        </NavButton>
        <NavButton onClick={() => navigate("/vets")}>
          <NavButtonImage src={FindVetImg} alt="Find Vet" />
          <NavButtonText>Find Vet</NavButtonText>
        </NavButton>
        <NavButton onClick={() => navigate("/groomers")}>
          <NavButtonImage src={FindGroomerImg} alt="Find Groomer" />
          <NavButtonText>Find Groomer</NavButtonText>
        </NavButton>
        <NavButton onClick={() => navigate("/trainers")}>
          <NavButtonImage src={FindTrainerImg} alt="Find Trainer" />
          <NavButtonText>Find Trainer</NavButtonText>
        </NavButton>
      </ButtonNav>
      <SupportButton onClick={() => navigate("/support")}>Support</SupportButton>
      <TextBlock>
        <Title>
          Explore PawTag™ Premium Features: Your GateWay To Enhanced Pet Care
        </Title>
        <PawtagImage src={pawtagImage} alt="PawTag Image" />
        <TextList>
          <li>Cloud-based pet profile and medical record for easy access</li>
          <li>Utilize the Lost Pet Feature for added security</li>
          <li>
            Stay on top of vaccinations and medications with timely reminders
          </li>
          <li>Benefit from the assistance of our intelligent Pet AI</li>
          <li>
            Connect with local vets, pet sitters, and trainers effortlessly
          </li>
          <li>Connect with friends and their furry companions</li>
        </TextList>
      </TextBlock>
      <ShopButton onClick={() => navigate("/")}>Shop Now</ShopButton>
     <Footer>
  <FooterTitle>Quick Links</FooterTitle>
  <FooterLinks>
    <FooterLink href="/support">Support</FooterLink>
    <FooterLink href="/PrivacyPolicy">Privacy Policy</FooterLink>
    <FooterLink href="/TermsAndConditions">Terms & Conditions</FooterLink>
  </FooterLinks>
</Footer>
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  background-color: #f5f7fc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  width: 200px;
  height: auto;

  @media (max-width: 600px) {
    width: 150px;
  }
`;

const ImageContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PetsImage = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;

const TextContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const SmallText = styled.p`
  color: #5951fc;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0;
  text-transform: uppercase;
`;

const LargeText = styled.p`
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 28px;
  margin: 10px 0;

  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const DownloadContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const DownloadLink = styled.a`
  margin: 0 10px;
`;

const AppStoreLogo = styled.img`
  width: 180px;
  height: auto;

  @media (max-width: 600px) {
    width: 150px;
  }
`;

const PlayStoreLogo = styled.img`
  width: 180px;
  height: auto;

  @media (max-width: 600px) {
    width: 150px;
  }
`;

const SearchContainer = styled.div`
  background-color: rgba(89, 81, 252, 0.2);
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  width: 85%;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    width: 85%;
  }
`;

const SearchForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 1024px) {
    width: 70%;
  }
`;

const SearchInput = styled.input`
  padding: 10px;
  border: none;
  border-radius: 20px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 600px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #5951fc;
  box-sizing: border-box;
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #5951fc;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #473fdd;
  }
`;

const ButtonNav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px; /* Ensure space below the nav buttons */
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5em;
`;

const NavButton = styled.button`
  height: 150px;
  width: 150px;
  margin: 10px;
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  color: #5951fc;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #f1f1f1;
  }
`;

const NavButtonImage = styled.img`
  height: 60px;
  width: 60px;
  margin-bottom: 10px;
`;

const NavButtonText = styled.span`
  color: #5951fc;
`;

const ShopButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #5951fc;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  margin-top: 20px; /* Ensure space from nav buttons */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for consistency */

  &:hover {
    background-color: #473fdd;
  }
`;

const TextBlock = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const PawtagImage = styled.img`
  width: 150px; // Adjust size as needed
  height: auto;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  color: #5951fc;
  font-family: "Montserrat", sans-serif;
  font-weight: 600; /* Semi-Bold */
  font-size: 24px;
  margin-bottom: 10px;
`;

const TextList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  color: #5e5d5d; /* Less black */
  font-family: "Montserrat", sans-serif;
  font-weight: 600; /* Semi-Bold */
  font-size: 14px; /* Smaller font size */
  padding: 0;
  margin: 0 auto;
  text-align: justify; /* Justify text */
  max-width: 600px;

  li {
    margin-bottom: 8px; /* Adjust spacing between list items */
  }
`;

const Footer = styled.footer`
  background-color: #5951fc;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
  margin-top: 20px;
`;

const FooterTitle = styled.h3`
  font-family: "Montserrat", sans-serif;
  font-weight: 700; /* Bold text */
  font-size: 24px; /* Larger text size */
  margin-bottom: 10px;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterLink = styled.a`
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: 5px 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const SupportButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 10px; /* Slightly rounded corners */
  background-color: orange;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  margin-top: 10px; /* Ensure space from nav buttons */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for consistency */

  &:hover {
    background-color: darkorange;
  }
`;


export default Home;
