import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getRequest } from '../utils/api';
import styled from 'styled-components';
import logo from '../assets/logo.png';
import loading_cat from '../assets/loading_cat.gif';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { FaPhoneAlt } from 'react-icons/fa'; // Import the phone icon


const PetSitterDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [sitter, setSitter] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSitter = async () => {
      try {
        const url = `${process.env.REACT_APP_HOSTNAME}/api/PetSitters/GetPetSitterById?id=${id}`;
        const data = await getRequest(url);
        setSitter(data.data[0]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sitter data:', error);
        setLoading(false);
      }
    };

    fetchSitter();
  }, [id]);

  if (loading) {
    return (
      <LoadingContainer>
        <img src={loading_cat} alt="Loading..." />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <Header>
        <LogoContainer>
          <Logo onClick={() => { navigate('/'); }} src={logo} alt="Pawers Logo" />
        </LogoContainer>
        <BackButton onClick={() => { navigate('/sitters'); }}>&larr; Back</BackButton>
      </Header>
      <Content>
        <Image src={sitter?.image} alt={sitter?.name} />
        <Info>
          <Name>{sitter?.name}</Name>
          <DetailBox>
            <PinIcon>📍</PinIcon>
            <DetailText>{sitter?.address}, {sitter?.city}, {sitter?.country}</DetailText>
          </DetailBox>
          <DetailBox>
            <SpecialtyIcon>💼</SpecialtyIcon>
            <DetailText>{sitter?.speciality}</DetailText>
          </DetailBox>
          <AboutTitle>About {sitter?.name}</AboutTitle>
          <Description>{sitter?.description}</Description>
          <ButtonContainer>
          <LinkButton href={sitter?.instagramUrl?.startsWith('http') ? sitter.instagramUrl : `https://${sitter.instagramUrl}`} target="_blank" rel="noopener noreferrer">
            <InstagramLogo src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png" alt="Instagram" />
            Instagram
            </LinkButton>
            <LinkButton href={sitter?.websiteUrl?.startsWith('http') ? sitter.websiteUrl : `https://${sitter.websiteUrl}`} target="_blank" rel="noopener noreferrer">
                <WebsiteLogo src="https://img.icons8.com/?size=100&id=42909&format=png&color=000000" alt="Website" />
                Website
            </LinkButton>
          </ButtonContainer>
          <ContactDetails>
          <PhoneButton href={`tel:${sitter?.phoneNumber}`} target="_blank" rel="noopener noreferrer">
            <FaPhoneAlt style={{ marginRight: '8px' }} />
            {sitter?.phoneNumber}
          </PhoneButton>      
         </ContactDetails>
        </Info>
        <MapContainer>
            {sitter ? (
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    center={{ lat: sitter.latitude, lng: sitter.longitude }}
                    zoom={15}
                >
                    <Marker position={{ lat: parseFloat(sitter.latitude), lng: parseFloat(sitter.longitude) }} />
                </GoogleMap>
                </LoadScript>
            ) : (
                <p>Map is not available</p>
            )}
        </MapContainer>
      </Content>
    </Container>
  );
};
export default PetSitterDetail;

// CSS Styled Components
const Container = styled.div`
  background-color: #f5f7fc; 
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  color: #5951fc;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #5951fc;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 40px;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5em;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
`;

const PhoneButton = styled.a`
  display: flex;
  align-items: center;
  background-color: #007bff; /* Blue color */
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: 500;

  &:hover {
    background-color: #0056b3; /* Darker blue for hover */
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;

  @media (min-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

const Info = styled.div`
  flex: 1;
  color: #5951fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Name = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const DetailBox = styled.div`
  background-color: #f5f5f5;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
`;

const DetailText = styled.p`
  font-size: 16px;
  color: #333;
  margin: 0;
`;

const PinIcon = styled.span`
  font-size: 20px;
  margin-right: 10px;
`;

const SpecialtyIcon = styled.span`
  font-size: 20px;
  margin-right: 10px;
`;

const AboutTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const LinkButton = styled.a`
  display: flex;
  align-items: center;
  border: 0.5px solid #5951fc; /* Border color */
  background-color: transparent; /* Transparent background */
  color: #5951fc; /* Text color to match the border */
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: 500;

  &:hover {
    background-color: #f0f0f0; /* Light background color on hover */
  }

  img {
    margin-right: 8px;
  }
`;



const InstagramLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const WebsiteLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;


const ContactDetails = styled.div`
  margin-top: 20px;
  text-align: center;
  width: 100%;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 300px;
  margin-top: 20px;
`;
