import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import image247 from "../assets/24-7.png"; // Import the image
import { FaWhatsapp } from 'react-icons/fa';

const Support = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Header>
        <LogoContainer>
          <Logo onClick={() => { navigate('/'); }} src={logo} alt="Pawers Logo" />
        </LogoContainer>
        <Link onClick={() => { navigate('/'); }}>
          <BackButton>&larr; Back</BackButton>
        </Link>
      </Header>
      <ContentContainer>
        <Title>Contact Us – Yes, We Answer!</Title>
        <Paragraph>
          Got questions or feedback? We're here to help! Whether you're curious
          about our platform, need assistance, or just want to chat, drop us a
          line using the form below. At Pawers, we're committed to providing
          timely and helpful responses. Your messages are important to us, and
          we're excited to hear from you!
        </Paragraph>
        <ImageContainer>
          <Image src={image247} alt="24/7 Support" />
        </ImageContainer>
        <ContactInfo>
          <ContactItem>
            <strong>Phone:</strong> 
            <PhoneNumber 
              href="tel:+96179169028"
              target="_blank"
              rel="noopener noreferrer"
            >
              +96179169028
            </PhoneNumber>
            <WhatsappIcon
              onClick={() => window.open('https://wa.me/96179169028', '_blank')}
            >
              <FaWhatsapp />
            </WhatsappIcon>
          </ContactItem>
          <ContactItem>
            <strong>Email:</strong> 
            <Email 
              href="mailto:pawers.lb@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              pawers.lb@gmail.com
            </Email>
          </ContactItem>
        </ContactInfo>
        <Paragraph>
          If you want your data or account deleted, you can do it from the app: Account &gt; Delete Account, or send us an email.
        </Paragraph>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f5f7fc; 
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  width: 200px;
  height: auto;

  @media (max-width: 600px) {
    width: 150px;
  }
`;

const ContentContainer = styled.div`
  text-align: center;
  margin-top: 40px; /* Increased margin */
  max-width: 600px;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 600px) {
    padding: 0 10px;
  }
`;

const Title = styled.h2`
  color: #5951fc;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const Paragraph = styled.p`
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 40px; /* Increased margin */
  line-height: 1.6;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 40px; /* Space between the image and the contact information */
`;

const Image = styled.img`
  width: 150px;
  height: auto;
  display: block;
  margin: 0 auto;

  @media (max-width: 600px) {
    width: 120px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  color: #000000;

  @media (max-width: 600px) {
    font-size: 16px;
    text-align: center;
  }
`;

const ContactItem = styled.div`
  margin-bottom: 20px; /* Increased margin */
  display: flex;
  align-items: center;

  strong {
    margin-right: 8px;
  }
`;

const PhoneNumber = styled.a`
  color: #007bff;
  text-decoration: none;
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Email = styled.a`
  color: #007bff;
  text-decoration: none;
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const WhatsappIcon = styled.div`
  color: #25D366;
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: #128C7E;
  }
`;

export default Support;
