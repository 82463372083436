import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../utils/api";
import styled from "styled-components";
import logo from "../assets/logo.png";
import loading_cat from "../assets/loading_cat.gif"; // Adjust the path as needed

const AdoptPet = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [petsByBreed, setPetsByBreed] = useState([]);
  const [petTypes, setPetTypes] = useState([]);
  const [selectedPetTypeId, setSelectedPetTypeId] = useState(1);
  const [offset, setOffset] = useState(1);
  const limit = 4;
  const navigate = useNavigate(); // Initialize the navigate hook

  useEffect(() => {
    const fetchPetTypes = async () => {
      try {
        const petTypesUrl = `${process.env.REACT_APP_HOSTNAME}/api/General/GetPetTypes`;
        const petTypesResponse = await getRequest(petTypesUrl);
        setPetTypes(petTypesResponse.data);

        const petUrl = `${process.env.REACT_APP_HOSTNAME}/api/Pets/GetPetsForAdoption?petTypeId=1&offset=1&limit=${limit}&searchTerm=`;
        const petDataResponse = await getRequest(petUrl);
        setPetsByBreed(petDataResponse.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPetTypes();
  }, []);

  const fetchPets = async (petTypeId, offset, limit) => {
    setLoading(true);
    try {
      const petUrl = `${process.env.REACT_APP_HOSTNAME}/api/Pets/GetPetsForAdoption?petTypeId=${petTypeId}&offset=${offset}&limit=${limit}&searchTerm=`;
      const petDataResponse = await getRequest(petUrl);
      setPetsByBreed(petDataResponse.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePetTypeClick = (petTypeId) => {
    setSelectedPetTypeId(petTypeId);
    setOffset(1);
    fetchPets(petTypeId, 1, limit);
  };

  const handleNextPage = () => {
    const newOffset = offset + 1;
    setOffset(newOffset);
    fetchPets(selectedPetTypeId, newOffset, limit);
  };

  const handlePrevPage = () => {
    const newOffset = offset - 1;
    if (newOffset > 0) {
      setOffset(newOffset);
      fetchPets(selectedPetTypeId, newOffset, limit);
    }
  };

  const handlePetCardClick = (petId) => {
    navigate(`/PetsAdoption/Details?petProfileId=${petId}`);
  };

  if (loading) {
    return (
      <LoadingContainer>
        <img src={loading_cat} alt="Loading..." />
      </LoadingContainer>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Container>
      <Header>
        <Link to="/">
          <BackButton
            onClick={() => {
              navigate("/");
            }}
          >
            &larr; Back
          </BackButton>
        </Link>
        <LogoContainer>
          <Logo
            onClick={() => {
              navigate("/");
            }}
            src={logo}
            alt="Pawers Logo"
          />
        </LogoContainer>
      </Header>
      <ButtonContainer>
        {petTypes.map((petType) => (
          <PetButton
            key={petType.id}
            onClick={() => handlePetTypeClick(petType.id)}
          >
            <ColoredSvg src={petType.image} alt={petType.title} />
            {petType.title}
          </PetButton>
        ))}
      </ButtonContainer>
      {petsByBreed &&
        petsByBreed.map((breed) => (
          <BreedContainer key={breed.petBreedId}>
            <BreedTitle>{breed.petBreedTitle}</BreedTitle>
            <PetsContainer>
              {breed.pets.map((pet) => (
                <PetCard
                  key={pet.petId}
                  onClick={() => handlePetCardClick(pet.petId)}
                >
                  <PetImage src={pet.petImage} />
                  <PetInfo>
                    <PetName>{pet.petName}</PetName>
                    <PetDescription>{pet.age}</PetDescription>
                  </PetInfo>
                </PetCard>
              ))}
            </PetsContainer>
          </BreedContainer>
        ))}
      <PaginationContainer>
        <PaginationButton onClick={handlePrevPage} disabled={offset === 1}>
          Previous
        </PaginationButton>
        <PaginationButton
          onClick={handleNextPage}
          disabled={petsByBreed.length === 0}
        >
          Next
        </PaginationButton>
      </PaginationContainer>
    </Container>
  );
};

export default AdoptPet;

const Container = styled.div`
  background-color: #f5f7fc;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 50px;
  &:hover {
    text-decoration: underline;
  }
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
`;

const PetButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ColoredSvg = styled.img`
  width: 24px;
  height: 24px;
  filter: invert(30%) sepia(74%) saturate(295%) hue-rotate(197deg)
    brightness(97%) contrast(97%);
`;

const BreedContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const BreedTitle = styled.h2`
  text-align: center;
  margin-bottom: 10px;
`;

const PetsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

const PetCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const PetImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
`;

const PetInfo = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const PetName = styled.h2`
  font-size: 18px;
  margin: 0;
`;

const PetDescription = styled.p`
  font-size: 14px;
  color: #666;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
