import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../utils/api";
import logo from "../assets/logo.png";
import loading_cat from "../assets/loading_cat.gif"; // Adjust the path as needed

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [htmlContent, setHtmlContent] = useState(""); // State to store HTML content

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const PrivacyPolicyUrl = `${process.env.REACT_APP_HOSTNAME}/api/LegalDocuments/GetPrivacyPolicy`;
        const pivayPolicyResponse = await getRequest(PrivacyPolicyUrl);
        setHtmlContent(pivayPolicyResponse.data.htmlContent); // Store HTML content in state
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPrivacyPolicy();
  }, []);

  if (loading) {
    return (
      <LoadingContainer>
        <img src={loading_cat} alt="Loading..." />
      </LoadingContainer>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Container>
      <Header>
        <LogoContainer>
          <Logo onClick={() => { navigate('/'); }} src={logo} alt="Pawers Logo" />
        </LogoContainer>
        <Link onClick={() => { navigate('/'); }}>
          <BackButton>&larr; Back</BackButton>
        </Link>
      </Header>
      <ContentContainer>
        {/* Render HTML content safely */}
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f5f7fc; 
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  width: 200px;
  height: auto;

  @media (max-width: 600px) {
    width: 150px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ContentContainer = styled.div`
  max-width: 800px;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  /* Responsive styles */
  @media (max-width: 600px) {
    padding: 10px;
    margin-top: 10px;
  }

  /* Style HTML content */
  h1, h2, h3, h4, h5, h6 {
    color: #333;
  }
  
  p {
    color: #666;
    line-height: 1.6;
  }
`;

export default PrivacyPolicy;
